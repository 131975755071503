<template>
    <div class="banner">
        <img :src="image" class="bars-image" />
    </div>
</template>

<script>
import { loadPictureConfig } from "@/api/api";
export default {
    props: ['rulename'],
    created() {
        this.loadDataInfo();
    },
    data() {
        return {
			image: ''
        };
    },
    methods: {
        loadDataInfo(){
			// 查询banner图片
			loadPictureConfig({
                rule: this.rulename,
            }).then((res) => {
                if (res.success) {
                    this.image = `/jeecg-boot/${res.result.theLocal}`;
                }
            });
		}
    },
}
</script>

<style lang="scss" scoped>
.banner{
    .bars-image{
        width: 100%;
    }
}
</style>