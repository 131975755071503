<template>
    <div>
        <div class="navig">
            <div class="tuo">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/' }"
                        ><i class="el-icon-s-home"></i>首页</el-breadcrumb-item
                    >
                    <el-breadcrumb-item v-for="(item,index) in crumblist" :key="index">
                        <span v-if="!item.url">{{ item.name }}</span>
                        <router-link v-else :to="item.url">{{ item.name }}</router-link>
                    </el-breadcrumb-item>
                </el-breadcrumb>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['crumblist'],
    data() {
        return {
            
        }
    },
}
</script>

<style lang="scss" scoped>
.navig {
    margin-top: 30px;
    margin-bottom: 43px;
    background-color: #f4f4f4;
    height: 46px;
    .tuo {
        padding-top: 16px;
        margin-left: 18px;
        .el-breadcrumb__inner{
            a:hover{
                color: #6EB400;
            }
        }
    }
}
</style>