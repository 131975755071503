<template>
  <section class="main-header clear">
    <div class="header-box w">
      <div class="logo lf">
        <router-link to="/">
        <img src="@/assets/image/main/logo.png" alt="" class="logo-img" />
        </router-link>
      </div>
      <nav class="main-nav lf">
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          background-color="#fff"
          text-color="#000"
          active-text-color="#FFF"
          router
        >
          <el-menu-item index="/">首页</el-menu-item>
          <el-menu-item index="/survey">崇信概况</el-menu-item>
          <el-submenu index="2">
            <template slot="title">景点与路线</template>
            <el-menu-item index="/scenicspot">旅游景点</el-menu-item>
            <el-menu-item index="/touristrou">旅游路线</el-menu-item>
            <el-menu-item index="/tourismbus">旅游商业</el-menu-item>
            <el-menu-item index="/specialtour">特种旅游</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template slot="title">旅游文化</template>
            <el-menu-item index="/tourism/list/1">崇信文物</el-menu-item>
            <el-menu-item index="/tourism/list/2">崇信名人</el-menu-item>
            <el-menu-item index="/tourism/list/3">崇信民俗</el-menu-item>
            <el-menu-item index="/tourism/list/4">崇信名吃</el-menu-item>
            <el-menu-item index="/tourism/list/5">崇信特产</el-menu-item>
            <el-menu-item index="/tourism/list/6">旅游文学</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template slot="title">旅游管理</template>
            <el-menu-item index="/tourismmana">旅游管理机构</el-menu-item>
            <el-menu-item index="/tourismasso">旅游协会</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template slot="title">旅游企业</template>
            <el-menu-item index="/hotel">旅游饭店</el-menu-item>
            <!-- <el-menu-item index="5-2">旅游交通</el-menu-item> -->
            <el-menu-item index="/travelagency">旅行社</el-menu-item>
            <el-menu-item index="/travelshopping">旅游购物</el-menu-item>
            <el-menu-item index="/entertainment">娱乐设施</el-menu-item>
            <!-- <el-menu-item index="5-6">网上预订</el-menu-item> -->
          </el-submenu>
          <el-submenu index="6">
            <template slot="title">信息查询</template>
            <el-menu-item index="/touristinfo">旅游须知</el-menu-item>
            <el-menu-item index="/dynamic">旅游动态</el-menu-item>
            <el-menu-item index="/festival">旅游节庆</el-menu-item>
            <el-menu-item index="/strategy">虚拟旅游</el-menu-item>
            <el-menu-item index="/traffic">交通指南</el-menu-item>  
            <el-menu-item index="/meteor">气象服务</el-menu-item>
            <el-menu-item index="/ticketprice">门票价格</el-menu-item>
            <el-menu-item index="/travelmap">旅游地图</el-menu-item>
            <el-menu-item index="/books">书籍光碟</el-menu-item>
          </el-submenu>
           <!-- <el-menu-item index="/">"崇信人"</el-menu-item> -->
        </el-menu>
      </nav>
      <div class="login-regir lr">
        <div v-if="!islogin" style="padding-top: 7px;">
          <span class="text" @click="openRegiser">注册</span>
          <el-divider direction="vertical"></el-divider>
          <span class="text" @click="openLogin">登陆</span>
        </div>
        <div v-else>
          <div class="userinfo clear">
            <img
              v-if="form.avatar"
              :src="`/jeecg-boot/${form.avatar}`"
              alt="头像"
              class="touxiang clear"
            />
            <img
              v-else
              src="@/assets/image/user/avatar.jpg"
              alt="头像"
              class="touxiang clear"
            />
            <div class="mzi clear">
              <el-dropdown>
                <span class="el-dropdown-link">
                  {{ form.username
                  }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <router-link to="/user">
                    <el-dropdown-item>基本信息</el-dropdown-item>
                  </router-link>
                  <router-link to="/user/mtravels"
                    ><el-dropdown-item>我的游记</el-dropdown-item></router-link
                  >
                  <router-link to="/user/writeadd">
                    <el-dropdown-item>我要写游记</el-dropdown-item>
                  </router-link>
                  <el-dropdown-item @click.native="loginOut"
                    >退出</el-dropdown-item
                  >
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Register ref="register" />
    <Login ref="login" />
  </section>
</template>

<script>
import Register from "@/components/Register";
import Login from "@/components/Login";
import { logout } from "@/api/login.js";
export default {
  created() {
    let token = localStorage.getItem("token");
    let userinfo = localStorage.getItem("userInfo");
    if (token) {
      this.islogin = true;
    }
    if (userinfo) {
      this.form = JSON.parse(userinfo);
    }
  },
  components: {
    Register,
    Login,
  },
  data() {
    return {
      form: {},
      activeIndex: "1",
      islogin: false,
    };
  },
  methods: {
    openRegiser() {
      this.$refs.register.visibles = true;
    },
    openLogin() {
      this.$refs.login.visibles = true;
    },
    loginOut() {
			const token = localStorage.getItem("token");
			let _this = this;
			logout(token).then((res) => {
				if (res.success) {
					_this.$message.success(res.message);
					localStorage.clear();
					location.reload()
				}
			});
		},
  },
};
</script>

<style lang="scss" scoped>
.main-header {
  height: 84px;
  background-color: #fff;
  .header-box {
    padding-top: 12px;
    .main-nav {
      margin-left: 30px;
      margin-top: 10px;
      ::v-deep.el-menu--horizontal {
        border-bottom: 0;
        & > .el-menu-item {
          height: 40px;
          line-height: 40px;
          border-bottom: 0;
          font-size: 16px;
          padding: 0 19px;
          &:hover {
            color: #6EB400!important;
            background-color:rgba(133, 125, 125, 0) !important;
          }
        }
        & > .el-submenu {
          &:hover {
            .el-submenu__title {
              color: #6EB400!important;
            background-color:rgba(133, 125, 125, 0) !important;
            }
          }
          .el-submenu__title {
            height: 40px;
            line-height: 40px;
            border-bottom: 0;
            font-size: 16px;
            padding: 0 16px;
            i {
              display: none;
            }
          }
        }
      }
    }
    .login-regir {
      .el-divider {
        background-color: #ccc;
        height: 16px;
        top: -2px;
      }
      .text {
        color: #000;
        font-size: 16px;
        transition: all 0.6s;
        &:hover{
          color: #6EB400;
        }
      }
    }
  }
  .userinfo {
    .touxiang {
      width: 34px;
      height: 34px;
      border-radius: 15px;
      margin-right: 10px;
    }
    .mzi {
      float: right;
      margin-top: 5px;
      .el-dropdown {
        color: #6eb400;
      }
    }
  }
}
.login-regir {
  padding-top: 13px;
  .el-divider {
    background-color: #fff;
    height: 16px;
    top: -2px;
  }
  .text {
    color: #fff;
    font-size: 16px;
  }
}
.text:hover {
  cursor: pointer;
}
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #ecf5ff;
  color: #6EB400;
}
.logo-img{
  width:276px
}
</style>
